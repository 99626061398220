import React from 'react';
import './SideBar.css'
import File from './file.json'
import Lottie from "lottie-react";
import TypeWriterEffect from "react-typewriter-effect";

const SideBar = () => {
    return (
      <section>
        <div className="border p-10 mt-12 mb-10 mx-20 bg-white rounded-2xl">
          <TypeWriterEffect
            textStyle={{
              fontFamily: "monospace",
              fontSize: "35px",
              marginLeft: "35%",
            }}
            startDelay={10}
            cursorColor="black"
            text="www.sureflybd.com"
            typeSpeed={100}
          />
          <h1 className=" text-2xl mar">
            <marquee>
              Something amazing will be constructed here........
            </marquee>
          </h1>
          <Lottie loop={true} className=" h-[500px] " animationData={File} />
        </div>
        <div className="border mx-20 p-10 mb-10 rounded-2xl bg-white">
          <div className=" flex justify-around">
            <div className="flex items-center mar">
              <img
                className=" h-9"
                src="https://cdn-icons-png.flaticon.com/512/9073/9073336.png"
                alt=""
                srcset=""
              />
              <h1 className=" ml-5 text-2xl ">8801731185386,8801716039993</h1>
            </div>
            <div className=" flex  items-center">
              <img
                className="h-9"
                src="https://cdn-icons-png.flaticon.com/512/2504/2504957.png"
                alt=""
                srcset=""
              />
              <h1 className=" text-2xl ml-5 mar ">8801914386745</h1>
            </div>
          </div>
          <div className=" mt-5 flex items-center">
            <div className="flex items-center ml-[121px]">
              <img
                className=" h-9"
                src="https://cdn-icons-png.flaticon.com/512/9194/9194940.png"
                alt=""
                srcset=""
              />
              <h1 className=" text-2xl mar ml-5">
                8802226637047,8801611185386
              </h1>
            </div>
            <div className=" flex items-center ml-[250px]">
              <img
                className=" h-9"
                src="https://cdn-icons-png.flaticon.com/512/552/552486.png"
                alt=""
                srcset=""
              />
              <h1 className=" text-2xl mar ml-5"> info@sureflybd.com</h1>
            </div>
          </div>
          <div className=" mt-3 flex items-center ml-[121px]">
            <img
              className="h-9"
              src="https://cdn-icons-png.flaticon.com/512/9062/9062564.png"
              alt=""
              srcset=""
            />
            <h1 className=" text-[20px] ml-5 mar">
              SOLEMAN PLAZA, 8th FLOOR, ROOM #04, 162 SHAHID SYED NAZRUL ISLAM
              SORONY <span className=' block'>(OLD- 3/3B PURANA PALTAN, DHAKA-1000.)</span>
            </h1>
          </div>
          <div className="mt-10 flex justify-center ">
            <a
              href="https://www.facebook.com/SUREFLYLIMITED"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className=" h-10"
                src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png"
                alt=""
                srcset=""
              />
            </a>
          </div>
        </div>
      </section>
    );
};

export default SideBar;