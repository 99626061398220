import './App.css';
import SideBar from './Components/SideBar/SideBar';

function App() {
  return (
    <div>
        <SideBar/>
    </div>
  );
}

export default App;
